.page2-container{
    position: fixed;
    background-color: white;
    /* background: linear-gradient(45deg, rgba(239, 8, 8, 0.8) , rgb(93, 93, 225) , rgba(239, 8, 8, 0.8)); */
    height: 60vh;
    width: 60vw;
    top: 20vh;
    left: 20vw;
    border-radius: 4.5vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: black;

  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
  background-color: rgba(255, 255, 255, .15);
  
  backdrop-filter: blur(40px);
}
.page2-heading{
    font-size: 34px;
    color: red;
    width: 100%;
    text-align: center;
    font-weight: 600;
}
.page2-details{
    display: flex;
    height: 60%;
    justify-content: space-evenly;
}
.page2-col1 , .page2-col2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 44%;
}
.page2-input{
    border: 1px solid rgb(83, 77, 77);
    padding: 5px;
    border-radius: 5px;
    margin-left: 1%;
    height: 30px;
    width: 12vw;
}
/* .page2-line{
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    height: 15%;
} */
.page2-name-input{
    margin-left: 16%;
}
.page2-yos-input{
    margin-left: 2.6%;
}
.page2-gender-input{
    width: 50%;
    margin-left: 13%;
}
.page2-email-input{
    margin-left: 15%;
}
.page2-phone-input{
    margin-left: 6%;
}
.page2-alternate-input{
    margin-left: 4%;
}
.page2-bottom{
    height: 10%;
}
.page2-prev-next{
    display: flex;
    justify-content: space-evenly;
}
.page2-last{
    width: 30%;
    /* background-color: rgb(79, 79, 179); */
    background-color: rgb(46, 33, 214);
    padding: 8px;
    border-radius: 10px;
    color: white;
}


@media(max-width: 1300px){
    .page2-container{
        width: 70vw;
        left: 15vw;
    }
}
@media(max-width: 950px){
    .page2-container{
        width: 80vw;
        left: 10vw;
    }
}
@media(max-width: 700px){
    .page2-container{
        width: 85vw;
        left: 7.5vw;
    }
}
@media(max-width: 600px){
    .page2-container{
        height: 65%;
    }
    .page2-heading{
        font-size: 23px;
    }
    .page2-details{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70%;
    }
    .page2-col1 , .page2-col2{
        height: 50%;
        width: 80%;
    }
    .page2-col1 * , .page2-col2 *{
        height: 20%;
    }
    .page2-input{
        width: 50%;
        height: 80%;
    }
    .page2-name-input{
        margin-left: 20%;
    }
    .page2-gender-input{
        margin-left: 18%;
    }
    .page2-email-input{
        margin-left: 23.5%;
    }
    .page2-position-input{
        margin-left: 5%;
    }
    .page2-phone-input{
        margin-left: 11%;
    }
    .page2-alternate-input{
        margin-left: 9%;
    }
}
@media(max-width: 380px){
    .page2-input{
        width: 45%;
    }
}

