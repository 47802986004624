.page3-container{
    position: fixed;
    background-color: white;
    /* background: linear-gradient(45deg, rgba(239, 8, 8, 0.8) , rgb(93, 93, 225) , rgba(239, 8, 8, 0.8)); */
    height: 60vh;
    width: 60vw;
    top: 20vh;
    left: 20vw;
    border-radius: 4.5vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: black;

  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
  background-color: rgba(255, 255, 255, .15);
  
  backdrop-filter: blur(40px);
}
.page3-heading{
    font-size: 34px;
    color: red;
    width: 100%;
    text-align: center;
    font-weight: 500;
}
.page3-details{
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-around;
    /* align-items: center; */
}
.page3-details *{
    display: flex;
    align-items: center;
    justify-content: center;
}
.page3-address-label , .page3-pin-label , .page3-city-label , .page3-state-label{
    /* align-self: center;*/
    display: flex;
    width: auto;
}
/* .page3-address{
    display: flex;
} */
.page3-input{
    border: 1px solid rgb(93, 86, 86);
    padding: 7px;
    margin-left: 20px;
    width: 60%;
    border-radius: 10px;
    /* padding-left: 60px; */
}
.page3-pin-input{
    margin-left: 6%;
}
.page3-city-input{
    margin-left: 8%;
}
.page3-state-input{
    margin-left: 7%;
}
.page3-last{
    height: 30px;
    display: flex;
    justify-content: space-around;
    height: 10%;
    color: white;
}
.page3-button{
    background-color: rgb(46, 33, 214);
    width: 30%;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    align-content: center;
}
@media(max-width: 800px){
    .page3-heading{
        font-size: 24px;
    }
}
@media(max-width: 650px){
    .page3-heading{
        font-size: 22px;
    }
    .page3-address-label{
        max-width: 10%;
        margin-left: 5%;
    }
    .page3-address-input{
        margin-left: 14%;
    }
}
@media(max-width: 1300px){
    .page3-container{
        width: 70vw;
        left: 15vw;
    }
}
@media(max-width: 950px){
    .page3-container{
        width: 80vw;
        left: 10vw;
    }
}
@media(max-width: 700px){
    .page3-container{
        width: 85vw;
        left: 7.5vw;
    }
}