.choose-sport-blur{
    /* background-color: green; */
}
.choose-sport-blur label{
    background-color: rgb(251, 254, 251);
    border-color: green ;
    color: green ;
    /* border: 0; */
    /* background-color: transparent; */
}
.choose-sport-blur img{
    opacity: 0.5;
}
/* .choose-sport-img-blur{
    opacity: 0.5;
} */

.label-container {
    border-radius: 50%; /* Make the container circular */
    width: 10rem; /* Set the width and height for consistency */
    height: 10rem;
    overflow: hidden; /* Hide content outside the circular boundary */
  }

  .label-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }