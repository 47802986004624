@media only screen and (min-width: 1024px){
    #confirmation {
        left: 35%;
    }
}

@media only screen and (max-width: 1023px) {
    #confirmation {
        justify-content: center;
    }
}