.page1-container{
    position: fixed;
    background-color: white;
    /* background: linear-gradient(45deg, rgba(239, 8, 8, 0.8) , rgb(93, 93, 225) , rgba(239, 8, 8, 0.8)); */
    height: 60vh;
    width: 60vw;
    top: 20vh;
    left: 20vw;
    border-radius: 4.5vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: black;

  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
  background-color: rgba(255, 255, 255, .15);
  
  backdrop-filter: blur(40px);
}
.page1-heading{
    font-size: 34px;
    text-align: center;
    text-decoration: underline;
    /* padding-bottom: 5px; */
    font-weight: 500;
    top: 35%;
    color: red;
}
.insti{
    width: 70%;
    height: 20%;
    padding: 15px;
    position: relative;
    top: 10%;
    left: 15%;
    overflow: hidden;
    
}
.insti-name-heading{
    /* padding-bottom: 40px; */
    margin-bottom: 70px;
    font-size: 20px;
    /* background-color: aqua; */
    position: absolute;
    width: 100%;

    /* left: 15%; */
    top: 0%;
    /* font-size: 20px; */

}
.insti-input{
    width: 90%;
    height: 43%;
    /* margin-top: 40px; */
    position: absolute;
    border: 2px solid rgb(149, 143, 143) ;
    border-radius: 8px;
    bottom: 10%;
    padding: 6px;
    /* left: 15%; */
}
.reg-back-img{
    height: 100vh;
    width: 100vw;
}
.participate-as{
    text-align: center;
    left: 10%;
    top: 20%;
    width: 80%;
    position: relative;
    /* height: 20%; */
    display: flex;
    justify-content: space-evenly;
}
.participate-as-label{
    width: 40%;
    font-size: 20px;
}
.participate-as-select{
    width: 30%;
    border: 2px solid rgb(149, 143, 143) ;
}






.page1-next{
    position: absolute;
    /* top: 65%; */
    width: 60%;
    left: 20%;
    height: 10%;
    bottom: 15%;
}
.page1-next-button{
    background-color: rgb(46, 33, 214);
    width: 100%;
    color: white;
    height: 100%;
    border-radius: 10px;
}


.bgimg{
    max-width: unset !important;
}

@media(max-width: 1300px){
    .page1-container{
        width: 70vw;
        left: 15vw;
    }
}
@media(max-width: 950px){
    .page1-container{
        width: 80vw;
        left: 10vw;
    }
    .bgimg{
        width: 1350px !important;
        overflow: hidden;
        position: absolute;
    }
}
@media(max-width: 800px){
    .participate-as{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .participate-as label{
        width: 95%;
    }
}
@media(max-width: 700px){
    .page1-container{
        width: 85vw;
        left: 7.5vw;
    }
}
@media(max-width: 400px){
    .page1-heading{
        font-size: 24px;
    }
}

/* @media (max-width:1000px) {
    
} */