.user-sport-details-active{
    position: absolute !important;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 80vw;
    visibility: visible;
}
.user-sport-details-hidden{
    position: fixed !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 80vw;
}